import * as Chart from 'chart.js';
import { filter, map, takeUntil } from 'rxjs';

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { CoreComponent } from './core/core.component';

import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends CoreComponent implements OnInit, AfterViewInit {
  public isAdminRoute: boolean = false;
  public isSociuuHubRoute: boolean = false;

  // Banner configs
  private bannerCookie: string = '';
  public isBannerHidden: boolean = true;
  private currentBannerType: string = 'howToNativePost';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.setBannerCookie();
    this.checkCurrentPlatform();
  }

  public ngAfterViewInit(): void {
    Chart.plugins.register({
      afterDraw: function (chart) {
        if (chart.data.datasets[0].data.length === 0) {
          // No data is present
          const ctx = chart.chart.ctx;
          const width = chart.chart.width;
          const height = chart.chart.height;
          chart.clear();

          ctx.save();
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = '16px bold';
          ctx.fillText(`Sorry there wasn't enough data`, width / 2, height / 2);
          ctx.fillText(`to show statistics`, width / 2, height / 2 + 16);
          ctx.restore();
        }
      },
    });
  }

  public closeBanner(): void {
    this.cookieService.set('currentBannerType', this.currentBannerType);
    this.setBannerCookie();
  }

  private setBannerCookie(): void {
    this.bannerCookie = this.cookieService.get('currentBannerType');
    if (!this.isBannerHidden) this.isBannerHidden = this.currentBannerType === this.bannerCookie;
  }

  private checkCurrentPlatform(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event) => event instanceof NavigationEnd),
        map((): any => {
          let child = this.route.firstChild;
          let data: any = null;

          while (child) {
            if (child.snapshot.data && (child.snapshot.data['isAdmin'] || child.snapshot.data['isSociuuhub'])) return child.snapshot.data;
            else if (child.firstChild) child = child.firstChild;
            else return null;
          }
          return data;
        })
      )
      .subscribe((response: any): void => {
        if (response) {
          this.isAdminRoute = response.isAdmin;
          this.isSociuuHubRoute = response.isSociuuhub;
        }
      });
  }
}
