let env = 'production';
let apiUrl = 'https://api.sociuu.app/api';
let baseUrl = 'https://api.sociuu.app';
let production = true;
let sociuuhubApiUrl = 'https://api.sociuu.app/sociuuhub';

export let SENTRY_AUTH_TOKEN = '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';
export let sentryDSN = 'https://3f7f49b1abeab7c35ddd70890ec5b474@o156193.ingest.us.sentry.io/4507571113885696';

export let environment = {
  env: env,
  apiUrl: apiUrl,
  baseUrl: baseUrl,
  production: production,
  sociuuhubApiUrl: sociuuhubApiUrl,
  intercomAppId: 'knxbdngc',
  sociuu_demo_instance: false,
  sentryDSN: 'https://3f7f49b1abeab7c35ddd70890ec5b474@o156193.ingest.us.sentry.io/4507571113885696',
};